// import React from 'react'

// const PageContainer = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default PageContainer

import React from 'react';
import HeaderDefault from '../Shared/HeaderDefault';
import FooterDefault from '../Shared/FooterDefault';
import FooterBar from '../Shared/FooterBar';
import { Helmet } from 'react-helmet';


const initHeaders = (
    <>
        <HeaderDefault />
    </>
);
const initFooters = (
    <>
        <FooterDefault />
        <FooterBar />
    </>
);

const PageContainer = ({
    header = initHeaders,
    footer = initFooters,
    children,
    title = "Elfateh Elaraby | الفتح العربى", // Default title
}) => {


    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {header}
            {children}
            {footer}

        </>
    );
};

export default PageContainer;
