import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { HomePage } from '../../../translate/trans';
import { useSelector } from 'react-redux';
import { contactPhone, email } from '../../../Constant';

const Rights = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='rights' dir={dir}>
            <div className='row justify-content-between align-items-center'>
                <div className='col-lg-7 col-md-6 col-sm-12'>
                    <div className='right-info'>
                        <span className='mx-4 infoFooterContact'>
                            <FaLocationDot />
                            <span>{HomePage?.address[lang]} </span>
                        </span>

                        <span dir='ltr' className='mx-4 infoFooterContact'>
                            <FaPhoneAlt />
                            <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                        </span>

                        <span dir='ltr' className='mx-4 infoFooterContact'>
                            <MdEmail />
                            <a href={`mailto:${email}`}>{email}</a>
                        </span>
                    </div>
                </div>
                <div className='col-lg-5 col-md-6 col-sm-12' style={{ textAlign: "left" }}>
                    <p> ©2024.elfath elaraby.e. All rights reserved</p>
                </div>
                <div className='col-lg-5 col-md-6 col-sm-12' style={{ textAlign: "left" }}>
                    <a  target="_blank" href='https://osbash.com'  rel="noopener noreferrer"> developed by OSBASH</a>
                </div>
            </div>
        </div>
    )
}

export default Rights
