import React, { useState } from 'react';
import { FaHome, FaSearch, FaBriefcase, FaSuitcase } from 'react-icons/fa'; // Icons (you can replace them with the ones you prefer)
import "../../Styles/shared.css";
import { PiExportFill } from "react-icons/pi";
import { LiaLocationArrowSolid } from "react-icons/lia";
import { Dropdown } from 'react-bootstrap';
import { RiHome2Line } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {HomePage} from "../../translate/trans"
const FooterBar = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const [showSearchModal, setShowSearchModal] = useState(false);
    const isServiceActive = [
        '/categories-mining',
        '/categories-furniture',
        '/categories-language'
    ].includes(location.pathname) || isOpen;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleSearchClick = (e) => {
        e.preventDefault();
        setShowSearchModal(true);
    };
    return (
        <div className="footer-bar shadow">
            <div style={{ textAlign: "center" }}>
                <NavLink to="/" className={({ isActive }) => isActive ? "arrowLocation navFooterBar" : "inactive-nav"}>
                    <RiHome2Line size={"20px"} className='iconData' /> <p>{HomePage.home[lang]}</p>
                </NavLink>
            </div>
            {/* <div style={{ textAlign: "center" }}>
                <NavLink to="/search" className={({ isActive }) => isActive ? "arrowLocation navFooterBar" : "inactive-nav"}>
                    <FaSearch size={"20px"} className='iconData' /> <p>البحث</p>
                </NavLink>
            </div> */}
            <div style={{ textAlign: "center" }}>
                    <div onClick={handleSearchClick} className={location.pathname === '/search' ? "arrowLocation navFooterBar" : "inactive-nav"}>
                        <FaSearch size={"20px"} className='iconData' /> <p>{HomePage.search[lang]}</p>
                    </div>
                </div>
            <div style={{ textAlign: "center", marginTop: "-25px" }} >
                <NavLink to="/contact-us" className="arrowLocation navFooterBar iconArrow">
                    <LiaLocationArrowSolid color="white" size={"20px"} />
                </NavLink>
            </div>
            <div style={{ textAlign: "center" }}>
                <NavLink to="/Portfolio" className={({ isActive }) => isActive ? "arrowLocation navFooterBar" : "inactive-nav"}>
                    <PiExportFill size={"20px"} className='iconData' /> <p>Portfolio</p>
                </NavLink>
            </div>
            {/* <div style={{ textAlign: "center" }}>
                <NavLink to="/categories-mining" className={({ isActive }) => isActive ? "arrowLocation navFooterBar" : "inactive-nav"}>
                    <FaBriefcase size={"20px"} className='iconData' /> <p>الخدمات</p>
                </NavLink>
            </div> */}
            <div style={{ textAlign: "center", position: "relative" }}>
                <div
                    onClick={toggleDropdown}
                    className={isServiceActive ? "arrowLocation navFooterBar" : "inactive-nav"}
                    style={{ cursor: "pointer" }}
                >
                    <FaBriefcase size={"20px"} className='iconData' />
                    <p>{HomePage.services[lang]}</p>
                </div>

                {isOpen && (
                    <div className="custom-dropdown">
                        <NavLink to="/categories-mining" className="dropdown-item">{HomePage.Mining[lang]}</NavLink>
                        <NavLink to="/categories-furniture" className="dropdown-item">{HomePage.furFactory[lang]}</NavLink>
                        <NavLink to="/categories-language" className="dropdown-item">{HomePage.LanguageCenter[lang]}</NavLink>
                    </div>
                )}
            </div>
            <Modal show={showSearchModal} onHide={() => setShowSearchModal(false)}>
            
                <Modal.Body>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="ابحث هنا..."
                        autoFocus
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default FooterBar;
