import React from 'react'
import Elfatah from "../../assets/who.png"
import { useSelector } from 'react-redux';
import { HomePage } from '../../translate/trans';
const WhoUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='who-us' dir={dir}>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <p className='who-title'>{HomePage.aboutUs[lang]}</p>
                        <h2>{HomePage.whoFatah[lang]}</h2>
                        <p>
                            {HomePage.contentAboutUs[lang]}
                        </p>
                        <button className='btn more mt-3'>{HomePage.more[lang]}</button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <img src={Elfatah} alt="الفتح العربي" loading='lazy' className='w-100' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoUs
