import React from 'react'
import Fatah from "../../../assets/who.png";
import { useSelector } from "react-redux"
import "./owner.css";
// import Lady from "../../../assets/lady.png"
import Lady from "../../../assets/lady.jpeg"
import { HomePage } from '../../../translate/trans';
import { BsCheckAll } from "react-icons/bs";
import Slider from './slider';
import {urlBase} from "../../../redux/Api"
const FooterAbout = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const Achivments = [
        {
            name: HomePage?.Community_Development[lang],
            details: [
                { name: HomePage?.Conferences[lang] },
                { name: HomePage?.Community_civilization_development[lang] },
                { name: HomePage?.Organizing_Equestrian_Festivals[lang] }
            ]
        },
        {
            name: HomePage?.Entrepreneurship[lang],
            details: [
                { name: HomePage?.Al_Fath_Al_Arabi_Furniture_Factory[lang] },
                { name: HomePage?.Al_Fath_Al_Arabi_Educational_Center[lang] },
                { name: HomePage?.Horse_Trade[lang] },
                { name: HomePage?.Real_Estate[lang] },
                { name: HomePage?.Livestock_Trade[lang] }
            ]
        },

    ]

    const Conferences = [
        { name:HomePage?.South_Valley_University_Conference[lang],link: "https://youtu.be/iFOMYKYg_zA?si=x-j9VWJJZvye9lkX",source:"yt" },
        {name:HomePage?.Arab_Academy[lang],link:`${urlBase}/Arab_Academy.mp4`,source:"fb"},
        {name:HomePage?.The_Second_International_Conference_of_the_Faculty_of_Mass_Communication[lang],link:`${urlBase}/The_Second_International_Conference_of_the_Faculty_of_Mass_Communication.mp4`,source:'fb'},
        {name:HomePage?.Conference_to_support_President_Abdel_Fattah_El_Sisi[lang],link:`${urlBase}/Conference_to_support_President_Abdel_Fattah_El_Sisi.mp4`,source:"yt"},
        {name:HomePage?.Future_of_the_Nation_Party_Conference[lang],link:`${urlBase}/Future_of_the_Nation_Party_Conference.mp4`,source:"fb"},
        {name:HomePage?.Ministry_of_Education_Development_Conference[lang],link:`${urlBase}/Ministry_of_Education_Development_Conference.mp4`,source:"fb"},
        {name:HomePage?.Discussion_on_education_development[lang],link:`${urlBase}/Discussion_on_education_development.mp4`, source:"fb"},
    ]

    // const generalVideos=[
    // ]
    const Community_civilization= [
        {name:HomePage?.Interview_with_the_Chairman_of_the_Abu_Dhabi_Sports_Council[lang],link:`${urlBase}/Interview_with_the_Chairman_of_the_Abu_Dhabi_Sports_Council.mp4`,source:'fb'},
        {name:HomePage?.Exclusive_interview_with_the_Chairman_of_the_Supreme_Horse_Racing_Authority[lang],link:`${urlBase}/Exclusive_interview_with_the_Chairman_of_the_Supreme_Horse_Racing_Authority.mp4`,source:"fb"},
        {name:HomePage?.Equestrian_Festival_2024[lang],link:`${urlBase}/Equestrian_Festival_2024.mp4`,source:"fb"},
        {name:HomePage?.Speech_of_the_Conference_in_Support_of_President[lang],link:`${urlBase}/Speech_of_the_Conference_in_Support_of_President.mp4`,source:"fb"},
        {name:HomePage?.Voter_Awareness_Conference[lang],link:`${urlBase}/Voter_Awareness_Conference.mp4`,source:"fb"},
        {name:HomePage?.Opening_of_Al_Arabi_Sports_Club[lang],link:`${urlBase}/Opening_of_Al_Arabi_Sports_Club.mp4`,source:"fb"},
        {name:HomePage?.Opening_of_Al_Arabi_Sports_Club[lang] + '2',link:`${urlBase}/Opening_of_Al_Arabi_Sports_Club2.mp4`,source:"fb"},
        {name:HomePage?.celebrates_Orphan_Day[lang],link:`${urlBase}/celebrates_Orphan_Day.mp4`,source:'fb'},
        {name:null,link:`${urlBase}/omrah.mp4`,source:"fb",},
        {name:null,link:`${urlBase}/edu.mp4`,source:"fb"},
        {name:HomePage?.Opening_of_the_new_fire_department_in_Edfu[lang],link:`${urlBase}/Opening_of_the_new_fire_department_in_Edfu.mp4`,source:"fb"},
]
    return (
        <div className="mt-5">

            <div>
                <div className="container">
                    <h3 className='section-title'>{HomePage?.owner[lang]} </h3>
                    <div className="row justify-content-between align-items-center">
                        {/* Text section */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div>
                                <h3>{HomePage.Biography[lang]}:</h3>
                                <p>
                                    {HomePage.aboutOwner[lang]}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 image-fatah-footer'>
                            <img
                                src={Lady}
                                alt="الفتح العربي"
                                loading='lazy'
                                style={{ width: "60%" }}
                            />
                        </div>

                    </div>
                    <div className='owner-portfolio'>
                        <h3 className='section-title'>{HomePage?.Achivments[lang]}</h3>
                        <ul className='unstyled-list'>
                            {Achivments?.map((ach, i) =>
                                <li key={i}>{<BsCheckAll size={"30px"} color='green' />}{ach?.name}
                                    <ul className='subDetails'>
                                        {ach?.details?.map((det, j) =>
                                            <li key={j}>{det?.name}</li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </ul>

                    </div>

                  <Slider title={HomePage?.Conferences[lang]} arr={Conferences}/>
                  <Slider title={HomePage?.Community_Development[lang]} arr={Community_civilization}/>
                  {/* <Slider  arr={generalVideos}/> */}
                </div>
            </div>
        </div>
    )
}

export default FooterAbout
