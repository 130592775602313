import React from 'react'
import PageContainer from '../../components/Layout/PageContainer'
import Furniture from '../../components/Static/Furniture'

const FurniturePage = () => {
    return (
        <div>
            <PageContainer title="الاثاث | الفتح العربى">
                <Furniture />
            </PageContainer>
        </div>
    )
}

export default FurniturePage
