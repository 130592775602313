
// Categories Static Page
export const CategoryOne = "شركة رائدة في مجال التعدين والاستكشاف في جمهورية مصر العربية"
export const CategoryTwo = `متخصصة في استخراج المعادن والموارد الطبيعية من باطن الأرض. تعمل الشركة على تطوير مشروعات تعدينية مستدامة تساهم في تعزيز الاقتصاد المحلي وتوفير فرص عمل متميزة. من خلال تبني أحدث التقنيات وأفضل الممارسات الصناعية، تسعى شركة الفتح العربي للتعدين إلى تحقيق أقصى استفادة من الثروات الطبيعية بشكل يضمن الحفاظ على البيئة ويلبي احتياجات السوق المتنامية
`

export const CategoryThree = ``


// furnture Static page

export const furOne = `مرحبًا بكم في الفتح العربي للأثاث، وجهتكم المثالية لعالم الأثاث الفاخر والتصاميم المبتكرة!`
export const furTwo = ` نحن مصنع رائدة في مجال الأثاث، ملتزمون بتقديم أفضل الحلول لتأثيث منازلكم ومكاتبكم بأعلى معايير الجودة والأناقة. تأسس المصنع عام 2016 لصاحبته الشريفة : كريمة العربي ، وإدارة أ. فؤاد اللبان في تصنيع وتوريد الأثاث عالي الدقة`
export const furThree = ` نسعى إلى أن نكون الخيار الأول لعملائنا من خلال تقديم أثاث يجمع بين الفخامة، الراحة، والعملية، لنساعدكم في تحويل منازلكم إلى مساحات تعكس شخصيتكم وأسلوب حياتكم
`

// Language


export const langOne = `شركة متخصصة في تقديم خدمات الترجمة واللغات، `
export const langTwo = `
    تهدف إلى تلبية احتياجات الشركات والأفراد في التواصل الفعال عبر مختلف اللغات والثقافات. تقدم الشركة مجموعة متنوعة من الخدمات اللغوية تشمل الترجمة التحريرية والفورية، والتدقيق اللغوي، وتقديم الاستشارات اللغوية المتخصصة، مما يساعد العملاء على توسيع نطاق أعمالهم ودخول أسواق جديدة بثقة. 
`
export const langThree = `بفضل فريق من المترجمين والمختصين اللغويين ذوي الخبرة والكفاءة العالية، تضمن شركة الفتح العربي لمكتب اللغات تقديم خدمات عالية الجودة والدقة، مع مراعاة الالتزام بالمواعيد واحتياجات العملاء المتنوعة. الشركة تسعى دائمًا لتحقيق التكامل بين الاحترافية والمرونة في التعامل مع مختلف المشاريع اللغوية لضمان رضا العملاء وتلبية توقعاتهم.`

export const contactPhone = "+20 1050046220";
export const email = "elsharifah@elfatehelaraby.com"