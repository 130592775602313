import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import AboutUsPage from "./Pages/Static/AboutUsPage";
import CategoryPage from "./Pages/Static/CategoryPage";
import FurniturePage from "./Pages/Static/FurniturePage";
import LangPage from "./Pages/Static/LangPage";
import Portflio from "./Pages/Static/Portflio";
import ContactUs from "./Pages/Static/ContactUs";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<HomePage/>}/>
        <Route path="/" element={<HomePage />} />

        <Route path="/about-us" element={<AboutUsPage />} />

        <Route path="/categories-mining" element={<CategoryPage />} />
        <Route path="/categories-furniture" element={<FurniturePage />} />
        <Route path="/categories-language" element={<LangPage />} />
        <Route path="/Portfolio" element={<Portflio />} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
