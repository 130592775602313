import React from 'react'
import WelcomImage from "../../assets/welcomeImage.png"
import WelcomImageTwo from "../../assets/welcomeImageTwo.png"
import { HomePage } from '../../translate/trans'
import { useSelector } from 'react-redux'
const Welcome = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='welcome-section' dir={dir}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div>
                            <div className='image-container'>
                                <img src={WelcomImage} alt="الفتح العربي" loading='lazy' className='w-100' />
                                <div className='overlay'></div>
                            </div>

                            <div className='image-container-child'>
                                <img src={WelcomImageTwo} alt="الفتح العربي" loading='lazy' className='w-100' />
                                <div className='overlay'></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='welcome-content'>
                            <div className='welcome-text'>
                                {HomePage.welcomeFatah[lang]}
                            </div>
                            <div style={{ padding: "20px" }}>
                                <p>{HomePage.activities[lang]}</p>
                                <div className='infoWelcom'>
                                    <h2>{HomePage.manyFileds[lang]}</h2>
                                    <p>{HomePage.advOne[lang]}</p>
                                    <ol>
                                        <li>{HomePage.advTwo[lang]}</li>
                                        <li>{HomePage.advthree[lang]}</li>
                                        <li>{HomePage.advFour[lang]}</li>
                                    </ol>
                                    <p>
                                        {HomePage.advFive[lang]}
                                    </p>
                                    <p>
                                        {HomePage.advSix[lang]}
                                    </p>

                                    <button className='btn more'>{HomePage.more[lang]}</button>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome
