import React from 'react'
import Fatah from "../../../assets/who.png";
import { useSelector } from 'react-redux';
const SharedHeader = ({ title, secondTitle, thirdTitle, img }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={dir}>
            <div className="row justify-content-center align-items-center">
                <div className='col-lg-4 col-md-6 col-sm-12 image-fatah' >
                    <img
                        src={img}
                        alt="الفتح العربي"
                        loading='lazy'
                        style={{ width: "100%" }}
                    />
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div>
                        <h3>{title}</h3>
                        <p>
                            {secondTitle}
                        </p>

                        <p className="mt-3">
                            {thirdTitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SharedHeader
