import React from 'react'
import Language from '../../components/Static/Language'
import PageContainer from '../../components/Layout/PageContainer'

const LangPage = () => {
    return (
        <div>
            <PageContainer title="مركز التعليم | الفتح العربى">
                <Language />
            </PageContainer>
        </div>
    )
}

export default LangPage
