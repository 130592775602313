import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import serviceOne from "../../assets/serviceone.png"
import serviceTwo from "../../assets/servicetwo.png"
import serviceThree from "../../assets/servicethree.png"
import { Card } from 'react-bootstrap';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translate/trans';
const Service = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='services text-center'>
            <div className='service-title'>
                <p>{HomePage.services[lang]}</p>
            </div>
            <p className='service-info'>
                {HomePage.serviceContent[lang]}
            </p>
            <div className='container'>
                <div className="">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        navigation
                        // pagination={{ clickable: true }}

                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="d-flex justify-content-center">
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img
                                        variant="top"
                                        src={serviceOne}
                                    />
                                    <Card.Body style={{ background: "#F4F5F8" }}>
                                        <Card.Title >{HomePage.FurnitureFactory[lang]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="d-flex justify-content-center">
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img
                                        variant="top"
                                        src={serviceTwo}
                                    />
                                    <Card.Body style={{ background: "#F4F5F8" }}>
                                        <Card.Title>{HomePage.languageCentre[lang]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="d-flex justify-content-center">
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img
                                        variant="top"
                                        src={serviceThree}
                                    />
                                    <Card.Body style={{ background: "#F4F5F8" }}>
                                        <Card.Title>{HomePage.Miningservices[lang]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="d-flex justify-content-center">
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img
                                        variant="top"
                                        src={serviceOne}
                                    />
                                    <Card.Body style={{ background: "#F4F5F8" }}>
                                        <Card.Title >{HomePage.FurnitureFactory[lang]}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <button className='btn more mt-5'>{HomePage.more[lang]}</button>
        </div>
    )
}

export default Service
