import React from 'react'
import "../../Styles/shared.css"
import Logo from "../../assets/logo.png"
import { Link, NavLink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setLang } from '../../redux/actions/language'
import { HomePage } from '../../translate/trans'

const HeaderDefault = () => {

    const dispatch = useDispatch();
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <header className="header" dir={dir}>
                <nav className="navbar">
                    <ul className="nav-items right-nav" dir={dir}>
                        <li><NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>{HomePage.home[lang]}</NavLink></li>
                        <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>
                            {HomePage.aboutUs[lang]}
                        </NavLink></li>
                        <li>
                            <Dropdown className="serviceTap" style={{ marginTop: "-5px" }}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: "#1A3667", backgroundColor: "transparent", border: "none", boxShadow: "none", marginRight: "10px" }}>
                                    {HomePage.Services[lang]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={NavLink} to={`/categories-mining`} >{HomePage.Mining[lang]}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={`/categories-furniture`} >
                                        {HomePage.furFact[lang]}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={`/categories-language`} >
                                        {HomePage.eductionCenter[lang]}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>

                    <div className="logo">
                        <Link to="/"><img src={Logo} alt="الفتح العربي" loading='lazy' /></Link>
                    </div>

                    <ul className="nav-items left-nav">
                        {/* <li><NavLink className={({ isActive }) => isActive ? "active" : ""} to="/Portfolio">Portfolio</NavLink></li> */}
                        {/* <li><NavLink className={({ isActive }) => isActive ? "active" : ""} to="/partners">
                            {HomePage.partners[lang]}
                        </NavLink></li> */}
                        <li><NavLink className={({ isActive }) => isActive ? "active" : ""} to="/contact-us">
                            {HomePage.ContactUs[lang]}
                        </NavLink></li>
                        <li>
                            <select
                                className="form-select form-control"
                                style={{ background: "transparent", border: "none", marginTop: "-4px" }}
                                aria-label="Default select example"
                                onChange={(e) => {
                                    dispatch(setLang(e.target.value));
                                }}
                                value={lang}
                            >
                                <option hidden>اختر اللغه</option>
                                <option value="EN">EN</option>
                                <option value="AR">AR</option>
                            </select>
                        </li>
                    </ul>


                </nav>
            </header>
        </div>
    )
}

export default HeaderDefault
