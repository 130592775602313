import React, { useState } from 'react'
import ImageSlider from "../../assets/sliderOne.png"
import ImageSliderTwo from "../../assets/sliderTwo.png"
import ImageSliderthree from "../../assets/sliderThree.png"
import ImageSliderFour from "../../assets/sliderFour.png"
import ImageSliderFive from "../../assets/sliderFive.png"
import { Container, Carousel } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { HomePage } from "../../translate/trans"
const HomeHeader = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item className="slider-background" interval={2000}>
                        <div className="d-flex flex-row justify-content-center align-items-center caursolItem">
                            {/* <div className="carousel-image-wrapper"> */}
                            <img


                                className="carousel-image"
                                src={ImageSlider}
                                alt="First slide"
                            />
                            {/* </div> */}
                            <div className='titleCaursol' dir={dir}>
                                <p>
                                    {HomePage.infoOne[lang]}
                                </p>
                                <span>
                                    {HomePage.contentInfone[lang]}
                                </span>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="slider-background" interval={2000}>
                        <div className="d-flex flex-row justify-content-center align-items-center caursolItem">
                            {/* <div className="carousel-image-wrapper"> */}
                            <img

                                className="carousel-image"
                                src={ImageSlider}
                                alt="First slide"
                            />
                            {/* </div> */}
                            <div className='titleCaursol' dir={dir}>
                                <p>
                                    {HomePage.infoThree[lang]}
                                </p>
                                <span>
                                    {HomePage.infoThreeContent[lang]}
                                </span>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="slider-background" interval={2000}>
                        <div className="d-flex flex-row justify-content-center align-items-center caursolItem">
                            {/* <div className="carousel-image-wrapper"> */}
                            <img

                                className="carousel-image"
                                src={ImageSliderTwo}
                                alt="First slide"
                            />
                            {/* </div> */}
                            <div className='titleCaursol' dir={dir}>
                                <p>
                                    {HomePage.infoTwo[lang]}
                                </p>
                                <span>
                                    {HomePage.infoTwoContent[lang]}
                                </span>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="slider-background" interval={2000}>
                        <div className="d-flex flex-row justify-content-center align-items-center caursolItem">
                            {/* <div className="carousel-image-wrapper"> */}
                            <img

                                className="carousel-image"
                                src={ImageSliderFour}
                                alt="First slide"
                            />
                            {/* </div> */}


                            <div className='titleCaursol' dir={dir}>
                                <p>
                                    {HomePage.infoFour[lang]}
                                </p>
                                <span>
                                    {HomePage.infoContentFour[lang]}
                                </span>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="slider-background" interval={2000}>
                        <div className="d-flex flex-row justify-content-center align-items-center caursolItem">
                            {/* <div className="carousel-image-wrapper"> */}
                            <img

                                className="carousel-image"
                                src={ImageSliderFive}
                                alt="First slide"
                            />
                            {/* </div> */}

                            <div className='titleCaursol' dir={dir}>
                                <p>
                                    {HomePage.infoFive[lang]}
                                </p>
                                <span>
                                    {HomePage.infoFiveContent[lang]}
                                </span>
                            </div>
                        </div>
                    </Carousel.Item>

                </Carousel>
            </>
        </div>
    )
}

export default HomeHeader
