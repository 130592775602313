import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const FacebookVideoPlayer = ({ url }) => {
  const [isPlayable, setIsPlayable] = useState(true);
  const [sdkLoaded, setSdkLoaded] = useState(false);

  useEffect(() => {
    // Check if URL is playable by ReactPlayer
    const testPlayer = ReactPlayer.canPlay(url);
    setIsPlayable(testPlayer);

    // Load Facebook SDK only if needed
    if (!testPlayer && !sdkLoaded) {
      loadFacebookSDK()
        .then(() => setSdkLoaded(true))
        .catch((error) => console.error("Facebook SDK failed to load:", error));
    }
  }, [url, sdkLoaded]);

  useEffect(() => {
    // Initialize Facebook plugin if SDK is loaded
    if (!isPlayable && window.FB) {
      window.FB.XFBML.parse();
    }
  }, [isPlayable, sdkLoaded]);

  // Function to load Facebook SDK
  const loadFacebookSDK = () => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=3043214452493893';
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  return (
    <div>
      {isPlayable ? (
        <ReactPlayer url={url} controls width="100%" height="auto" />
      ) : (
        <div className="fb-video" 
             data-href={url} 
             data-width="500" 
             data-allowfullscreen="true">
        </div>
      )}
    </div>
  );
};

export default FacebookVideoPlayer;
