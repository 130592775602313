export const HomePage = {
    // NavBar
    home: {
        AR: 'الصفحه الرئيسيه',
        EN: 'Home',
    },
    aboutUs: {
        AR: "من نحن",
        EN: "About Us"
    },
    Services: {
        AR: "خدمات",
        EN: "Services"
    },
    partners: {
        AR: "شركائنا",
        EN: "partners"
    },
    ContactUs: {
        AR: "تواصل معنا",
        EN: "Contact Us"
    },

    furFact: {
        AR: "مصنع الاثاث",
        EN: "Furniture factory"
    },
    eductionCenter: {
        AR: "مركز التعليم",
        EN: "Education Center"
    },
    Mining: {
        AR: "التعدين",
        EN: "Mining"
    },

    // HomeHeader
    infoOne: {
        AR: "   شركة رائدة تجمع بين مجموعة متنوعة من المجالات الحيوية لتلبية احتياجات السوق المتعددة",
        EN: "A leading company that combines a variety of vital fields to meet multiple market needs"
    },
    contentInfone: {
        AR: "تسعى الفتح العربي إلى تحقيق التميز في كل نشاطاتها من خلال الابتكار والالتزام بالجودة لتلبية تطلعات عملائها على الصعيدين المحلي والدولي",
        EN: "Al-Fath Al-Arabi seeks to achieve excellence in all its activities through innovation and commitment to quality to meet the aspirations of its customers at the local and international levels."
    },


    infoTwo: {
        AR: " منشأة صناعية متخصصة في تصميم وتصنيع الأثاث بمواصفات عالية الجودة",
        EN: "An industrial facility specialized in designing and manufacturing furniture with high quality specifications"
    },

    infoTwoContent: {
        AR: " إمكانية تخصيص التصاميم حسب طلب العميل. يركز المصنع على الجمع بين الجودة العالية والابتكار، مما يجعله وجهة متميزة لمن يبحث عن الأثاث الفاخر والعملي في نفس الوقت",
        EN: "Possibility of customizing designs according to customer request. The factory focuses on combining high quality and innovation, making it a distinguished destination for those looking for luxury and practical furniture at the same time"
    },

    infoThree: {
        AR: "تركز الشركة في هذا الجانب على تصنيع أو تجارة الأثاث، مما قد يشمل أثاث المنازل، المكاتب، أو الفروع التجارية",
        EN: "In this aspect, the company focuses on manufacturing or trading furniture, which may include furniture for homes, offices, or commercial branches",
    },
    infoThreeContent: {
        AR: " تصميم الأثاث حسب الطلب، وتوفير حلول أثاث مبتكرة تناسب احتياجات العملاء المختلفة.",
        EN: "Designing custom-made furniture, and providing innovative furniture solutions that suit different customer needs."
    },


    infoFour: {
        AR: " تقدم الشركة دورات لتعلم اللغات المختلفة، سواء كانت محلية أو دولية",
        EN: "The company offers courses to learn different languages, whether local or international",
    },
    infoContentFour: {
        AR: "  تقدم الشركة دورات موجهة للأفراد، يمكن أن تقدم الشركة خدمات تدريب للشركات والمؤسسات التي ترغب في رفع مستوى التواصل اللغوي بين موظفيها.",
        EN: "The company offers courses directed to individuals. The company can provide training services to companies and institutions that want to raise the level of linguistic communication among their employees."
    },

    infoFive: {
        AR: " تقدم الشركة خدمات متقدمة في قطاع التعدين",
        EN: "The company provides advanced services in the mining sector",
    },
    infoFiveContent: {
        AR: "تلتزم الشركة بتطبيق أحدث التقنيات في عمليات التعدين لضمان الكفاءة والجودة العالية، مع مراعاة المعايير البيئية والسلامة المهنية",
        EN: "The company is committed to applying the latest technologies in mining operations to ensure efficiency and high quality, while taking into account environmental and occupational safety standards",
    },


    //Welcome Section

    welcomeFatah: {
        AR: " مرحباً بك في شركة الفتح العربي",
        EN: "Welcome to Al-Fath Al-Arabi Company"
    },
    activities: {
        AR: "هى مؤسسة متعددة الانشطة",
        EN: "It is a multi-activity institution that "
    },
    manyFileds: {
        AR: "عمل فى عدة مجالات منها",
        EN: "works in several fields, including:"
    },

    advOne: {
        AR: "التعليم، تصنيع المنتجات الخشبية والاثاث ، والتعدين",
        EN: `
         education, Al-Fath Al-Arabi Center for Training and Languages, manufacturing of wood products and furniture:
`
    },
    advTwo: {
        AR: "مركز الفتح العربي التعليمي",
        EN: `Mining sector`
    },
    advthree: {
        AR: "مصنع الفتح العربى للأثاث",
        EN: "Al Fath Al Arabi Factory"
    },
    advFour: {
        AR: "الفتح العربي للتعدين",
        EN: "Al-Fath Al-Arabi Mining Company"
    },
    advFive: {
        AR: "المالك الشريفة كريمة محمد على السيد الشهيرة ب الشريفة كريمة العربى ابنت السيد الشريف المحض محمد ابو الفتوح العربى الادريسى",
        EN: `The honorable owner is Karima Muhammad Ali Al-Sayyid, known as Sharifa Karima Al-Arabi, daughter of Al-Sayyid Al-Sharif Al-Mahd Muhammad Abu Al-Futuh Al-Arabi Al-Idrissi
`
    },
    advSix: {
        AR: "وهى ايضا رئيس مجلس ادارة النادى العربى للفروسية",
        EN: "She is also the Chairman of the Board of Directors of the Arab Equestrian Club"
    },
    more: {
        AR: "اعرف اكثر",
        EN: "Read More"
    },


    // Services
    services: {
        AR: "خدماتنا",
        EN: "Services"
    },
    
    serviceContent: {
        AR: "نقدم مجموعة واسعة من الخدمات المتنوعة والمتميزة لتلبية احتياجات عملائنا في مختلف المجالات. تشمل خدماتنا:",
        EN: "We offer a wide range of diverse and distinguished services to meet the needs of our customers in various fields. Our services include:"
    },
    Miningservices: {
        AR: "خدمات التعدين",
        EN: "Mining services"
    },

    languageCentre: {
        AR: "مركز التدريب واللغات",
        EN: "Training and Languages Centre"
    },
    FurnitureFactory: {
        AR: "مصنع تصنيع الاثاث",
        EN: "Furniture manufacturing factory"
    },

    // About Us
    contentAboutUs: {
        AR: " مؤسسة الفتح العربي هي مؤسسة متعددة الأنشطة، تسعى لتقديم خدمات متنوعة تخدم المجتمع في عدة مجالات. تأسست المؤسسة بهدف تقديم حلول شاملة تجمع بين الجودة والاحترافية في مجالات التعليم، التصنيع، التعدين، والفروسية.",
        EN: "Who are we - Al-Fath Al-Arabi Foundation Al-Fath Al-Arabi Foundation is a multi-activity institution that seeks to provide various services that serve the community in several fields. The organization was established with the aim of providing comprehensive solutions that combine quality and professionalism in the fields of education, manufacturing, mining, and equestrianism."
    },

    whoFatah: {
        AR: "من نحن - مؤسسة الفتح العربي",
        EN: "Who are we - Al-Fath Al-Arabi Foundation"
    },

    // Partner
    partnerContetn: {
        AR: "   نحن نفتخر بشراكتنا مع مجموعة متميزة من الشركات والمؤسسات في مختلف القطاعات التي نعمل فيها. تعمل هذه الشراكات على تعزيز جودة خدماتنا وتوسيع نطاق أعمالنا لتحقيق أهدافنا المشتركة.",
        EN: "We are proud of our partnership with a distinguished group of companies and institutions in the various sectors in which we work. These partnerships enhance the quality of our services and expand our business to achieve our common goals."
    },

    // Contact Us
    infoContact: {
        AR: "نحن هنا لتلبية جميع استفساراتكم واحتياجاتكم. يمكنكم التواصل معنا عبر الوسائل التالية:",
        EN: "We are here to meet all your inquiries and needs. You can contact us through the following means:"
    },
    name: {
        AR: "الاسم",
        EN: "Name"
    },

    email: {
        AR: "البريد الالكتروني",
        EN: "Email"
    },

    message: {
        AR: "الرساله",
        EN: "Message"
    },
    sendMessage: {
        AR: "ارسال",
        EN: "Send"
    },

    loading:{
        AR:"تحميل",
        EN:"Loading"
    },

    // Footer
    factory: {
        AR: "مصانعنا",
        EN: "Factor"
    },
    employee: {
        AR: "موظفونا",
        EN: "Employee"
    },
    yearExp: {
        AR: "سنوات الخبره",
        EN: " Experiences"
    },

    footerContent: {
        AR: "نحن شركة الفتح العربي، شركة متعددة الأنشطة تجمع بين الخبرة والابتكار في مجالات مختلفة مثل الأثاث و تدريب اللغات والتعدين والفروسية",
        EN: "We are Al-Fath Al-Arabi Company, a multi-activity company that combines experience and innovation in various fields such as furniture, language training , Mining and equestrianism"
    },

    aboutPlatform: {
        AR: "عن المنصه",
        EN: "About Platform"
    },

    // About Us Page

    manyFeildsData: {
        AR: "مجالات عملنا",
        EN: "Our areas of work"
    },
    Outstandingeducation: {
        AR: "التعليم والتدريب",
        EN: "Outstanding education"
    },

    eductionContent: {
        AR: " تقدم المؤسسة من خلال مركز الفتح العربي للتدريب واللغات برامج تعليمية وتدريبية متقدمة تهدف إلى تأهيل الكوادر البشرية وتنمية المهارات في مجالات متعددة.",
        EN: "Through the Al-Fateh Arab Center for Training and Languages, the Foundation provides advanced military programs and training to qualify human cadres in multiple fields. we"
    },

    aboutContentFurniture: {
        AR: "تصنيع المنتجات الخشبية والأثاث",
        EN: "Manufacture of wood products and furniture"
    },
    aboutContentFurnitureContent: {
        AR: " يتميز مصنع الفتح العربي بإنتاج منتجات خشبية وأثاث عالي الجودة. نستخدم أفضل المواد الطبيعية ونتبع أساليب تصنيع حديثة لضمان تقديم منتجات تجمع بين الجمال، المتانة، والابتكار.",
        EN: " Al-Fath Al-Arabi factory is characterized by the production of high-quality wooden products and furniture. We use the best natural materials and follow modern manufacturing methods to ensure that products combine beauty, durability and innovation."
    },

    miningContent: {
        AR: " تعمل شركة الفتح العربي للتعدين في قطاع التعدين باحترافية، وتساهم في استخراج وتوزيع المواد الخام التي تعد من الأساسيات في العديد من الصناعات. نحرص على الالتزام بأعلى معايير السلامة والجودة في كل مراحل عملنا",
        EN: "Al-Fath Al-Arabi Mining Company works in the mining sector professionally, contributing to the extraction and distribution of raw materials, which are essential in many industries. We are committed to the highest standards of safety and quality at all stages of our work."
    },


    clubHorses: {
        AR: "الفروسيه",
        EN: "The Foundation also manages the Arab Equestrian Club, "
    },

    clubHorseContent: {
        AR: "  تدير المؤسسة أيضًا النادي العربي للفروسية الذي يعد من أبرز الأندية المتخصصة في رياضة الفروسية. نسعى من خلاله إلى نشر ثقافة الفروسية وتدريب محبي هذه الرياضة الأصيلة على أيدي مدربين محترفين. ونسعى الى تدشين أكاديمية الفتح العربي للفروسية قريباً",
        EN: "which is one of the most prominent clubs specialized in equestrian sports. Through it, we seek to spread the culture of equestrianism and train fans of this authentic sport at the hands of professional trainers."
    },

    owner: {
        AR: "رئيس مجلس الإدارة",
        EN: "Chairman"
    },

    aboutOwner: {
        AR: `
            تأسست المؤسسة على يد السيدة الشريفة كريمة محمد علي السيد، المعروفة بـ الشريفة كريمة العربي، وهي ابنة السيد الشريف المحض محمد أبو الفتوح العربي الإدريسي. الشريفة كريمة العربي ليست فقط المالكة، بل أيضًا رئيسة مجلس إدارة النادي العربي للفروسية، وتسعى دائمًا لتحقيق التفوق في جميع الأنشطة التي تديرها المؤسسة.
                                    تطمح مؤسسة الفتح العربي إلى أن تكون رائدة في مجالاتها المختلفة، مع الالتزام بالقيم الأخلاقية والمهنية العالية لتحقيق التنمية المستدامة والمساهمة الفعالة في خدمة المجتمع.
        `,
        EN: `The Foundation was founded by Mrs. Karima Mohamed Ali Al-Sayed, known as Al-Sharifa Karima Al-Arabi, who is the daughter of Mr. Al-Sharif Al-Mahdh Muhammad Aboul Fotouh Al-Arabi Al-Idrissi. Honorable Karima Al Arabi is not only the owner, but also the president of 
Board of Directors of the Arab Equestrian Club, and always strives to achieve excellence in all activities managed by the Foundation. Al-Fateh Al-Arabi Foundation aspires to be a pioneer in its various fields, while adhering to high ethical and professional values to achieve sustainable development and contribute effectively to community service.
`
    },


    categoryOne: {
        AR: "شركة رائدة في مجال التعدين والاستكشاف في جمهورية مصر العربية",
        EN: "A leading company in the field of mining and exploration in Egypt,"
    },
    categoryTwo: {
        AR: "متخصصة في استخراج المعادن والموارد الطبيعية من باطن الأرض. تعمل الشركة على تطوير مشروعات تعدينية مستدامة تساهم في تعزيز الاقتصاد المحلي وتوفير فرص عمل متميزة. من خلال تبني أحدث التقنيات وأفضل الممارسات الصناعية، تسعى شركة الفتح العربي للتعدين إلى تحقيق أقصى استفادة من الثروات الطبيعية بشكل يضمن الحفاظ على البيئة ويلبي احتياجات السوق المتنامية",
        EN: "Specializing in the extraction of minerals and natural resources from the ground. The company works to develop sustainable mining projects that contribute to strengthening the local economy and providing distinguished job opportunities. By adopting the latest technologies and best industry practices, Al-Fath Al-Arabi Mining Company seeks to make the most of natural resources in a way that ensures the preservation of the environment and meets the needs of the growing market."
    },

    categoryThree: {
        AR: "تلتزم الشركة بتطبيق معايير صارمة في الجودة والسلامة، مع الحرص على بناء علاقات طويلة الأمد مع عملائها وشركائها، محليًا ودوليًا.",
        EN: "Al-Fath Al-Arabi Mining Company works in the mining sector professionally, contributing to the extraction and distribution of raw materials, which are essential in many industries. We are committed to the highest standards of safety and quality at all stages of our work."
    },

    miningContentData: {
        AR: "  تعمل شركة الفتح العربي للتعدين في قطاع التعدين باحترافية، وتساهم في استخراج وتوزيع المواد الخام التي تعد من الأساسيات في العديد من الصناعات. نحرص على الالتزام بأعلى معايير السلامة والجودة في كل مراحل عملنا.",
        EN: "Al-Fath Al-Arabi Mining Company works in the mining sector professionally, contributing to the extraction and distribution of raw materials, which are essential in many industries. We are committed to the highest standards of safety and quality at all stages of our work."
    },



    // Furniture Page
    furOne: {
        AR: "مرحبًا بكم في الفتح العربي للأثاث، وجهتكم المثالية لعالم الأثاث الفاخر والتصاميم المبتكرة!",
        EN: `Welcome to Al Fath Al Arabi Furniture, your ideal destination for the world of luxury furniture and innovative designs!
`
    },
    furTwo: {
        AR: `نحن مصنع رائدة في مجال الأثاث، ملتزمون بتقديم أفضل الحلول لتأثيث منازلكم ومكاتبكم بأعلى معايير الجودة والأناقة. تأسس المصنع عام 2016 لصاحبته الشريفة : كريمة العربي ، وإدارة أ. فؤاد اللبان في تصنيع وتوريد الأثاث عالي الدقة`,
        EN: ` We are a leading manufacturer in the field of furniture, committed to providing the best solutions to furnish your homes and offices with the highest standards of quality and elegance.
`
    },
    furThree: {
        AR: "نسعى إلى أن نكون الخيار الأول لعملائنا من خلال تقديم أثاث يجمع بين الفخامة، الراحة، والعملية، لنساعدكم في تحويل منازلكم إلى مساحات تعكس شخصيتكم وأسلوب حياتكم",
        EN: `The factory was established in 2016 by its honorable owner: Karima Al-Arabi, and the management is A. Fouad Al-Labban manufactures and supplies high-precision furniture`
    },

    furFactory: {
        AR: "الاثاث",
        EN: "Furniture manufacturing"
    },

    vision: {
        AR: "مهمتنا",
        EN: "Our vision:"
    },

    visionContent: {
        AR: " في الفتح العربي للأثاث، نلتزم بتقديم منتجات عالية الجودة بأفضل الأسعار، مع التركيز على التفاصيل الدقيقة، والتصاميم المبتكرة التي تناسب جميع الأذواق. نهدف إلى توفير تجربة تسوق مميزة تشمل خدمة عملاء متميزة وتوصيل سريع وخدمات ما بعد البيع.",
        EN: `providing furniture that combines luxury, comfort, and practicality, to help you transform your homes into spaces that reflect your personality and lifestyle.
Our vision:
At Al Fateh Al Arabi Furniture, we are committed to providing high quality products at the best prices, with a focus on fine details, and innovative designs that suit all tastes. We aim to provide a distinctive shopping experience that includes outstanding customer service, fast delivery and after-sales services.
Values:
Quality: We use the best materials to ensure the durability and elegance of each piece of furniture.
Design: We offer a variety of modern and classic designs to suit all tastes and spaces.
Commitment: We are committed to providing an unforgettable shopping experience through continuous support and superior services.
Why choose us?
Wide selection of luxury furniture.
Competitive prices and unparalleled quality.
Round-the-clock customer service to ensure your complete satisfaction.
Fast and safe delivery to all parts of Egypt.
`
    },


    Values: {
        AR: "قيمنا",
        EN: "Values"
    },

    valueOne: {
        AR: "الجودة: نحرص على استخدام أفضل المواد لضمان متانة وأناقة كل قطعة أثاث",
        EN: "Quality: We use the best materials to ensure the durability and elegance of each piece of furniture."
    },

    valueTwo: {
        AR: "التصميم: نقدم مجموعة متنوعة من التصاميم العصرية والكلاسيكية التي تناسب كافة الأذواق والمساحات",
        EN: "Design: We offer a variety of modern and classic designs to suit all tastes and spaces.",
    },

    valueThree: {
        AR: "الالتزام: نحن ملتزمون بتقديم تجربة تسوق لا تُنسى من خلال دعم مستمر وخدمات متفوقة",
        EN: "Commitment: We are committed to providing an unforgettable shopping experience through continuous support and superior services."
    },

    chooseUs: {
        AR: "لماذا تختارنا؟",
        EN: "Why choose us?"
    },

    chooseOne: {
        AR: "تشكيلة واسعة من الأثاث الفاخر.",
        EN: "Wide selection of luxury furniture."
    },
    chooseThree: {
        AR: "أسعار تنافسية وجودة لا مثيل لها",
        EN: "Competitive prices and unparalleled quality."
    },
    chooseFour: {
        AR: "خدمة عملاء على مدار الساعة لضمان رضاك التام.",
        EN: "Round-the-clock customer service to ensure your complete satisfaction."
    },
    chooseFive: {
        AR: "توصيل سريع وآمن لجميع أنحاء مصر.",
        EN: "Fast and safe delivery to all parts of Egypt."
    },

    LanguageCenter: {
        AR: "مركز اللغات",
        EN: "Language Center"
    },

    langOne: {
        AR: `شركة متخصصة في تقديم خدمات الترجمة واللغات، `,
        EN: "A company specialized in providing translation and language services, "
    },
    langTwo: {
        AR: `
    تهدف إلى تلبية احتياجات الشركات والأفراد في التواصل الفعال عبر مختلف اللغات والثقافات. تقدم الشركة مجموعة متنوعة من الخدمات اللغوية تشمل الترجمة التحريرية والفورية، والتدقيق اللغوي، وتقديم الاستشارات اللغوية المتخصصة، مما يساعد العملاء على توسيع نطاق أعمالهم ودخول أسواق جديدة بثقة. 
`,
        EN: `It aims to meet the needs of companies and individuals in effective communication across different languages ​​and cultures. The company offers a variety of linguistic services, including translation and interpretation, proofreading, and specialized language consultations, helping clients expand their business and enter new markets with confidence.`
    },

    langThree: {
        AR: `بفضل فريق من المترجمين والمختصين اللغويين ذوي الخبرة والكفاءة العالية، تضمن شركة الفتح العربي لمكتب اللغات تقديم خدمات عالية الجودة والدقة، مع مراعاة الالتزام بالمواعيد واحتياجات العملاء المتنوعة. الشركة تسعى دائمًا لتحقيق التكامل بين الاحترافية والمرونة في التعامل مع مختلف المشاريع اللغوية لضمان رضا العملاء وتلبية توقعاتهم.`,
        EN: `Thanks to a team of highly experienced and competent translators and linguistic specialists, Al-Fath Al-Arabi Language Office Company guarantees the provision of high-quality and accurate services, taking into account punctuality and diverse customer needs. The company always seeks to achieve integration between professionalism and flexibility in dealing with various language projects to ensure customer satisfaction and meet their expectations.`,
    },


    // Contact Us Component 
    headerContact:{
        AR:"هل تريد التحدث معنا ؟ هنا يتم الرد على",
        EN:"Do you want to talk with us? Here your inquiries are "
    },
    answers:{
        AR:"استفساراتك",
        EN:"answered"
    },

    contactUsInfo:{
        AR:"إذا كان لديك أسئلة أو تعليقات أو اقتراحات؟ ما عليك سوى ملء النموذج وسنتواصل معك قريبًا.",
        EN:"If you have questions, comments or suggestions? Simply fill out the form and we will contact you shortly."
    },

    address:{
        AR:"المكتب الرئيسي: زايد بيفرلي هيلز بوابة سوديك (أ).الطابق 6 وحدة 601 مكتب رقم 1",
        EN:`Head office:
Zayed Beverly Hills Sodic Portal (A).6 th floor Unit 601 
Office n.1`
    },

    search:{
        AR:"البحث",
        EN:"Search"
    },
    carpet:{
        AR:"السجاد",
        EN:"Carpet"
    },
    
    // Owner translation
    Achivments:{
        AR:"الانجازات",
        EN:"Achivments"
    },
    Biography:{
     AR:"التعريف بالمالك",
     EN:"Biography"
    },
    Community_Development:{
        AR:"التنمية المجتمعية",
        EN:"Community Development"
    },
    Conferences:{
        AR:"المؤتمرات",
        EN:"Conferences"
    },
    Community_civilization_development:{
      AR:"التنمية الحضارية المجتمعية",
      EN:"Community civilization development"
    },


    Entrepreneurship:{
        AR:"ريادة الاعمال",
        EN:"Entrepreneurship"
    },
    Al_Fath_Al_Arabi_Furniture_Factory:{
        AR:"مصنع الفتح العربى للاثاث",
        EN:"Al Fath Al Arabi Furniture Factory",
    },
    Al_Fath_Al_Arabi_Educational_Center:{
        AR:"مركز الفتح العربى التعليمى",
        EN:"Al Fath Al Arabi Educational Center"
    },
    Al_Fath_Al_Arabi_Farm:{
        AR:"مزرعة الفتح العربى",
        EN:"Al Fath Al Arabi Farm",
    },
    Livestock_Trade:{
        AR:"تجارة المواشى",
        EN:"Livestock Trade"
    },
    Horse_Trade:{
        AR:"تجارة الخيول",
        EN:"Horse Trade"
    },
    Organizing_Equestrian_Festivals:{
        AR:"تنظيم مهرجانات الفروسية",
        EN:"Organizing Equestrian Festivals"
    },
    Real_Estate:{
        AR:"العقارات",
        EN:"Real Estate"
    },
    South_Valley_University_Conference:{
        AR:"مؤتمر جامعة جنوب الوادى ",
        EN:"South Valley University Conference"
    },
    Beni_Suef_University_Conference:{
        AR:"مؤتمر جامعة بنى سويف",
        EN:"Beni Suef University Conference"
    },
    Naval_Academy_Conference:{
        AR:"مؤتمر الاكاديمية البحرية",
        EN:"Naval Academy Conference"
    },
    Equestrian_Festivals:{
        AR:"مهرجانات الفروسية",
        EN:"Equestrian Festivals"
    },
    Ministry_of_Education_Development_Conference:{
        AR:"مؤتمر التطوير بوزارة التربية والتعليم",
        EN:"Ministry of Education Development Conference"
    },
    Arab_Academy:{
        AR:`الاكاديمية العربية "اهمية التعليم"`,
        EN:`Arab Academy "The Importance of Education"`
    },
    Discussion_on_education_development:{
        AR:"مناقشة حول تطوير التعليم",
        EN:"Discussion on education development"
    },
    The_Second_International_Conference_of_the_Faculty_of_Mass_Communication:{
        AR:"المؤتمرالدولى الثانى لكلية الاعلام جامعة مصر",
        EN:"The Second International Conference of the Faculty of Mass Communication, Misr University"
    },
    Conference_to_support_President_Abdel_Fattah_El_Sisi:{
        AR:"مؤتمر تاييد الرئيس عبد الفتاح السيسى",
        EN:"Conference to support President Abdel Fattah El-Sisi"
    },
    Opening_of_the_new_fire_department_in_Edfu:{
        AR:"افتتاح وحدة المطافى الجديدة بادفو",
        EN:"Opening of the new fire department in Edfu"
    },
    Opening_of_Al_Arabi_Sports_Club:{
        AR:"افتتاح نادى العربى الرياضى",
        EN:"Opening of Al Arabi Sports Club"
    },
    celebrates_Orphan_Day:{
        EN:"Al Fath Al Arabi Foundation celebrates Orphan Day",
        AR:"احتفال مؤسسة الفتح العربى بيوم اليتيم"
    },
    Future_of_the_Nation_Party_Conference:{
        EN:"Future of the Nation Party Conference",
        AR:"مؤتمر حزب مستقبل وطن"
    },
    Voter_Awareness_Conference:{
        AR:"مؤتمر توعية الناخبين - الانتخابات الرئاسية ٢٠٢٣ ",
        EN:"Voter Awareness Conference - Presidential Election 2023"
    },
    Exclusive_interview_with_the_Chairman_of_the_Supreme_Horse_Racing_Authority:{
        AR:"حوار خاص مع رئيس الهيئة العليا لسباق الخيل-2024",
        EN:"Exclusive interview with the Chairman of the Supreme Horse Racing Authority"
    },
    Speech_of_the_Conference_in_Support_of_President:{
        AR:"كلمة مؤتمر تاييد الرئيس عبد الفتاح السيسى -2023",
        EN:"Speech of the Conference in Support of President Abdel Fattah El-Sisi - 2023"
    },
    Interview_with_the_Chairman_of_the_Abu_Dhabi_Sports_Council:{
        AR:"حوار مع رئيس مجلس ابوظبى الرياضى  ا \ سعيد المهيرى",
        EN:"Interview with the Chairman of the Abu Dhabi Sports Council, Saeed Al Muhairi"
    },
    Equestrian_Festival_2024:{
        AR:"مهرجان الفروسية ٢٠٢٤ ",
        EN:"Equestrian Festival 2024"
    },
    
    


};