import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Card } from 'react-bootstrap';
import FacebookVideoPlayer from './videoPlayer';

function Slider({title,arr}) {

    
  return (
    <>
   {title? <h4 className='swipperTitle'>{title}</h4>:<></>}
    <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    spaceBetween={50}
    breakpoints={{
        640: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 50,
        },
    }}
    navigation
    // pagination={{ clickable: true }}

    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
>
    {arr?.map((c, i) =>
        <SwiperSlide key={i}>
            <div className="d-flex justify-content-center">
                <Card style={{ width: '18rem' }}>

                    <Card.Body style={{ background: "#F4F5F8" }}>
                        {c?.name?<Card.Title >{c?.name}</Card.Title>:<></>}
                    </Card.Body>
                    {/* Video Element */}
                    {c?.source==='yt'&&
                    <FacebookVideoPlayer url={c?.link} />
}
{c?.source==='fb'&&<><FacebookVideoPlayer url={c?.link}/></>}
                </Card>
            </div>
        </SwiperSlide>
    )}
</Swiper>
</>
  )
}

export default Slider