import React from 'react'
import AboutUs from '../../components/Static/AboutUs'
import PageContainer from '../../components/Layout/PageContainer'
import "../../Styles/static.css"
const AboutUsPage = () => {
    return (
        <div>
            <PageContainer title="من نحن | الفتح العربى">
                <AboutUs />
            </PageContainer>
        </div>
    )
}

export default AboutUsPage
