import React from 'react'
import BreadCrumb from '../Shared/BreadCrumb'
import SharedHeader from './modules/SharedHeader'
import { furOne, furTwo, furThree } from '../../Constant'
import Lang from "../../assets/lang.png"
import { HomePage } from '../../translate/trans'
import { useSelector } from 'react-redux';
const Furniture = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={dir}>
            <div>
                <div>
                    <BreadCrumb title={`${HomePage.services[lang]}`} secondTitle={`${HomePage.furFactory[lang]}`} />
                </div>

                <div className='container'>
                    <div>
                        <SharedHeader title={`${HomePage.furOne[lang]}`} secondTitle={`${HomePage.furTwo[lang]}`} thirdTitle={`${HomePage.furThree[lang]}`} img={Lang} />
                    </div>

                    <div className="container">
                        <div>
                            <h3>{HomePage.vision[lang]}</h3>
                            <p className="mt-5">
                                {HomePage.visionContent[lang]}
                            </p>
                        </div>

                        <div className="mt-5">
                            <h3>{HomePage.Values[lang]}</h3>
                            <ul>
                                <li>{HomePage.valueOne[lang]}</li>
                                <li>{HomePage.valueTwo[lang]}</li>
                                <li>{HomePage.valueThree[lang]}</li>
                            </ul>
                        </div>

                        <div className="mt-5">
                            <h3>{HomePage.chooseUs[lang]}</h3>
                            <ul>
                                <li>
                                    {HomePage.chooseOne[lang]}
                                </li>
                                <li>
                                    {HomePage.chooseThree[lang]}
                                </li>
                                <li>
                                    {HomePage.chooseFour[lang]}
                                </li>
                                <li>
                                    {HomePage.chooseFive[lang]}
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Furniture
