import React from 'react'
import BreadCrumb from '../Shared/BreadCrumb'
import SharedHeader from './modules/SharedHeader'
import { langOne, langTwo, langThree } from '../../Constant'
import LangImage from "../../assets/fur.png"
import { useSelector } from "react-redux"
import { HomePage } from '../../translate/trans'
const Language = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div>
                <BreadCrumb title={`${HomePage.aboutUs[lang]}`} secondTitle={`${HomePage.LanguageCenter[lang]}`} />
            </div>
            <div className="container">
                <div>
                    <SharedHeader title={`${HomePage.langOne[lang]}`} secondTitle={`${HomePage.langTwo[lang]}`} thirdTitle={`${HomePage.langThree[lang]}`} img={LangImage} />
                </div>
            </div>

        </div>
    )
}

export default Language
