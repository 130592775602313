import React from 'react'
import BreadCrumb from '../Shared/BreadCrumb'
import HeaderAbout from './modules/HeaderAbout'
import AboutContent from './modules/AboutContent'
import FooterAbout from './modules/FooterAbout'
import { useSelector } from "react-redux"
import { HomePage } from '../../translate/trans'
const AboutUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className="about-us" dir={dir}>
            <BreadCrumb title={`${HomePage.aboutUs[lang]}`} secondTitle={""} />

            <div>
                <HeaderAbout />
            </div>

            <div>
                <AboutContent />
            </div>

            <div>
                <FooterAbout />
            </div>
        </div>
    )
}

export default AboutUs
