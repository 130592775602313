import React from 'react'
import BreadCrumb from '../Shared/BreadCrumb'
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import FormContact from './modules/FormContact';
import "../../Styles/static.css"
import { useSelector } from "react-redux"
import { HomePage } from '../../translate/trans'
const ContactComponent = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div>
                <BreadCrumb title={`${HomePage.ContactUs[lang]}`} secondTitle={""} />
            </div>
            <div className='container' dir={dir}>
                <div className='row justify-content-between align-items-center handleContact'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='contact-content-info'>
                            <h2>
                                {HomePage.headerContact[lang]}
                                <span style={{ color: "#CA8C48" }}>{HomePage.answers[lang]}</span>
                            </h2>

                            <p className='mt-3'>
                                {HomePage.contactUsInfo[lang]}
                            </p>
                            <div>
                                <div className='right-info'>
                                    <div className='mx-4 infoFooterContact mt-4'>
                                        <MdEmail style={{ marginLeft: "10px" }} />
                                        <span>elsharifah@elfatehelaraby.com</span>
                                    </div>

                                    <div className='mx-4 infoFooterContact mt-4'>
                                        <FaPhoneAlt style={{ marginLeft: "10px" }} />
                                        <span>+20 1050046220</span>
                                    </div>

                                    <div className='mx-4 infoFooterContact mt-4'>
                                        <FaLocationDot style={{ marginLeft: "10px" }} />
                                        <span>{HomePage.address[lang]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <FormContact />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactComponent
