import axios from "axios";
export const urlBase = 'https://elfatehelaraby.com'


//----Contact----
export const contact = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/emails/contact`,
        data:data,
    });
};