import React from 'react'
import PageContainer from '../../components/Layout/PageContainer'
import "../../Styles/HomePage.css"
import HomeHeader from '../../components/Home/HomeHeader'
import Welcome from '../../components/Home/Welcome'
import Service from '../../components/Home/Service'
import WhoUs from '../../components/Home/whoUs'
import Partner from '../../components/Home/Partner'
import ContactUs from '../../components/Home/ContactUs'
const HomePage = () => {
    return (
        <div>
            <PageContainer>
                <HomeHeader />
                <Welcome />
                <Service />
                <WhoUs />
                <Partner />
                <ContactUs/>
            </PageContainer>
        </div>
    )
}

export default HomePage
