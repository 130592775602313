import React, { useState } from 'react'
import { useSelector } from "react-redux"
import { HomePage } from '../../../translate/trans';
import { contact } from '../../../redux/Api';
const FormContact = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    // Data For Contact
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false)

    const handleContactform = async () => {
        try {
            setLoading(true);
            const res = await contact({
                name: name,
                email: email,
                content: content
            })
            if (res?.status === 201) {
                alert("تم ارسال الرسالة بنجاح")
                setName("")
                setEmail("")
                setContent("")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div style={{ background: "#EEEEEE", padding: "50px", borderRadius: "20px" }}>
                <div className='w-100'>

                    <input type="text" name="name" id="name" className='form-control mt-1 ' placeholder={`${HomePage.name[lang]}`}
                        value={name} onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <input type="email" name="email" id="email" className='form-control mt-1 ' placeholder={`${HomePage.email[lang]}`}
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='mt-5'>

                    <textarea className='form-control mt-1 ' style={{ resize: "none" }}
                        value={content} onChange={(e) => setContent(e.target.value)}
                        placeholder={`${HomePage.message[lang]}`}
                        cols={"20"}
                        rows={"8"}
                    />
                </div>
                <div className='mt-5'>
                    <button className='btn more' onClick={handleContactform} disabled={loading}>
                        {HomePage.sendMessage[lang]}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default FormContact
