import React from 'react'
import { BiSolidLeftArrow } from "react-icons/bi";
import { useSelector } from 'react-redux';

const BreadCrumb = ({ title, secondTitle }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={dir}>
            <div className='bread-crumb' style={{ width: "100%", background: "#F4F5F8", textAlign: "center", padding: "20px", marginBottom: "50px" }}>
                <span className='titleBreadCrumb'>{title}</span>
                {
                    secondTitle === "" ? null : (
                        <>
                            <BiSolidLeftArrow size={"25px"} color="#093EB6" style={{ fontWeight: "900" }} />
                            <span className='titleBreadCrumb' >{secondTitle}</span>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default BreadCrumb
