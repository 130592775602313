import React from 'react';
import Fatah from "../../../assets/who.png";
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translate/trans';

const HeaderAbout = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    {/* Image section */}
                    <div className='col-lg-6 col-md-6 col-sm-12 image-fatah' >
                        <img
                            src={Fatah}
                            alt="الفتح العربي"
                            loading='lazy'
                            style={{ width: "100%" }}
                        />
                    </div>

                    {/* Text section */}
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div>
                            <h3>{HomePage.whoFatah[lang]}</h3>
                            <p>
                                {HomePage.contentAboutUs[lang]}
                            </p>
                        </div>

                        {/* Work fields section */}
                        <div>
                            <h3>{HomePage.manyFeildsData[lang]}</h3>
                            <div>
                                <h4>1-{HomePage.Outstandingeducation[lang]}</h4>
                                <p>
                                    {HomePage.eductionContent[lang]}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderAbout;
