import React from 'react'
import PageContainer from '../../components/Layout/PageContainer'
import Categories from '../../components/Static/Categories'

const CategoryPage = ({ key }) => {
    return (
        <div>
            <PageContainer title="التعدين | الفتح العربى">

                <Categories />

            </PageContainer>
        </div>
    )
}

export default CategoryPage
