import React from 'react'
import PageContainer from '../../components/Layout/PageContainer'
import BreadCrumb from '../../components/Shared/BreadCrumb'
import PortflioFur from '../../components/Home/PortflioFur';
import { HomePage } from '../../translate/trans';
import { useSelector } from 'react-redux';
const Portflio = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <PageContainer title="portfolio | الفتح العربى">
                <div>
                    <BreadCrumb title="Portfolio" secondTitle={""} />
                </div>
                <div>
                    <PortflioFur title={`${HomePage.furFactory[lang]}`} />
                </div>
                <div>
                    <PortflioFur title={`${HomePage.carpet[lang]}`} />
                </div>
            </PageContainer>
        </div>
    )
}

export default Portflio
