import React from 'react'
import { useSelector } from "react-redux"
import { HomePage } from '../../../translate/trans';
const AboutContent = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className="container mt-5">
            <div className="content-about">
                <h4>2-{HomePage.aboutContentFurniture[lang]}</h4>
                <p>
                    {HomePage.aboutContentFurnitureContent[lang]}
                </p>
            </div>
            <div className="content-about">
                <h4>3-{HomePage.Mining[lang]}</h4>
                <p>
                    {HomePage.miningContent[lang]}
                </p>
            </div>
            <div className="content-about">
                <h4>4-{HomePage.clubHorses[lang]}</h4>
                <p>
                    {HomePage.clubHorseContent[lang]}
                </p>
            </div>
        </div>
    )
}

export default AboutContent



