import React from 'react'
import "../../Styles/shared.css"
import Logo from "../../assets/logo.png"
import InfoFooter from './modules/InfoFooter'
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";
import Rights from './modules/Rights';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { HomePage } from '../../translate/trans';
const FooterDefault = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='footer'>
                <div>
                    <InfoFooter />
                </div>
                <div className='content-footer' style={{ borderRadius: "10px",padding:"10px" }} dir={dir}>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-6 col-md-12 col-12 col-sm-12 ' >
                            <div>
                                <img src={Logo} alt="الفتح العربي" loading='lazy' />
                                <p>
                                    {HomePage.footerContent[lang]}
                                </p>
                            </div>
                            <div>

                                <FaFacebookSquare size={"25px"} className='mx-2' />


                                <FaYoutube size={"25px"} className='mx-2' />


                                <TbWorld size={"25px"} className='mx-2' />


                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 col-sm-12 '>
                            <div className='list-footer '>
                                <ul className='text-center '>
                                    <span style={{ color: "#093EB6", fontWeight: "800" }}>{HomePage.aboutPlatform[lang]}</span>
                                    <li>
                                        <NavLink to="/about-us" style={{ textDecoration: "none", color: "black" }}>{HomePage.aboutUs[lang]}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/categories-furniture" style={{ textDecoration: "none", color: "black" }}>{HomePage.Services[lang]}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Portfolio" style={{ textDecoration: "none", color: "black" }}>Portfolio</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/partner" style={{ textDecoration: "none", color: "black" }}>{HomePage.partners[lang]}</NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to="/contact-us" style={{ textDecoration: "none", color: "black" }}>{HomePage.ContactUs[lang]}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Rights />
                </div>
            </div>
        </div>
    )
}

export default FooterDefault
