import React from 'react'
import BreadCrumb from '../Shared/BreadCrumb'
import SharedHeader from './modules/SharedHeader'
import { CategoryOne, CategoryTwo, CategoryThree } from '../../Constant'
import MinImage from "../../assets/min.png"
import { useSelector } from 'react-redux';
import { HomePage } from '../../translate/trans'
const Categories = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={dir}>
            <div>
                <BreadCrumb title={`${HomePage.services[lang]}`} secondTitle={`${HomePage.Mining[lang]}`} />
            </div>

            <div className='container'>
                <div>
                    <SharedHeader title={`${HomePage.categoryOne[lang]}`} secondTitle={`${HomePage.categoryTwo[lang]}`} thirdTitle={`${HomePage.categoryThree[lang]}`} img={MinImage} />
                </div>

                <div>
                    <p className="content-about" style={{ marginTop: "45px" }}>
                        {HomePage.miningContentData[lang]}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Categories
