import React from 'react'
import Fact from "../../../assets/factory.png"
import Groub from "../../../assets/groub.png"
import Exp from "../../../assets/exp.png"
import { useSelector } from 'react-redux'
import { HomePage } from '../../../translate/trans'

const InfoFooter = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='info-footer' dir={dir}>
                <div className='row  justify-content-center align-items-center text-center'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div>
                            <img src={Fact} alt="الفتح العربي" loading='lazy' />
                            <span className='mx-4 fs-2 text-center' style={{ lineHeight: "2px" }}>
                                <span>{HomePage.factory[lang]}</span>
                                <p className='text-center'>3</p>
                            </span>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div>
                            <img src={Groub} alt="الفتح العربي" loading='lazy' />
                            <span className='mx-4 fs-2 text-center' style={{ lineHeight: "2px" }}>
                                <span>{HomePage.employee[lang]}</span>
                                <p className='text-center'>30</p>
                            </span>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div>
                            <img src={Exp} alt="الفتح العربي" loading='lazy' />
                            <span className='mx-4 fs-2 text-center' style={{ lineHeight: "2px" }}>
                                <span> {HomePage.yearExp[lang]}</span>
                                <p className='text-center'>12</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default InfoFooter
