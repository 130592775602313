import React from 'react'
import PageContainer from '../../components/Layout/PageContainer'
import ContactComponent from '../../components/Static/Contact'
import "../../Styles/static.css"

const ContactUs = () => {

    return (
        <div>
            <PageContainer title="تواصل معنا | الفتح العربى">
                <ContactComponent/>
            </PageContainer>
        </div>
    )
}

export default ContactUs
