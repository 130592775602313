import React from 'react'
import partnerOne from "../../assets/partnerOne.png"
import partnerTwo from "../../assets/partnerTwo.png"
import partnerThree from "../../assets/partnerThreee.png"
import partnerFour from "../../assets/partnerFour.png"
import { HomePage } from '../../translate/trans'
import { useSelector } from 'react-redux'
const Partner = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='partner text-center' style={{ marginTop: "120px", marginBottom: "120px" }}>
            <div>
                <div className='service-title'>
                    <p>{HomePage.partners[lang]}</p>
                </div>
                <p className='service-info'>
                    {HomePage.partnerContetn[lang]}
                </p>

            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                        <div>
                            <img src={partnerOne} alt="الفتح العربي" loading='lazy' className='w-100' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                        <div>
                            <img src={partnerTwo} alt="الفتح العربي" loading='lazy' className='w-100' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                        <div>
                            <img src={partnerThree} alt="الفتح العربي" loading='lazy' className='w-100' />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                        <div>
                            <img src={partnerFour} alt="الفتح العربي" loading='lazy' className='w-100' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partner
