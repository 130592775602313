import React, { useState } from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { useSelector } from 'react-redux';
import { HomePage } from '../../translate/trans';
import { contact } from '../../redux/Api';
// import { contact } from '../../redux/actions/Api';

const ContactUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    // Data For Contact
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false)

    const handleContactform = async () => {
        try {
            setLoading(true);
            const res = await contact({
                name: name,
                email: email,
                content: content
            })
            if (res?.status === 201) {
                alert("تم ارسال الرسالة بنجاح")
                setName("")
                setEmail("")
                setContent("")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className='contact-us ' dir={dir}>
            <div>
                <div className='contact-title'>
                    <p>{HomePage.ContactUs[lang]}</p>
                </div>
                <p className='mt-4 text-center'>
                    {HomePage.infoContact[lang]}
                </p>
            </div>
            <div className='form-contact-us' >
                <div>
                    <div className='mt-3'>
                        <label>{HomePage.name[lang]}</label>
                        <input type="text" name="name" id="name" className='form-control mt-1 inputs' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='mt-3'>
                        <label>{HomePage.email[lang]}</label>
                        <input type="email" name="email" id="email" className='form-control mt-1 inputs'
                            value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mt-3'>
                        <label>{HomePage.message[lang]}</label>
                        <textarea className='form-control mt-1 inputs' style={{ resize: "none" }}
                            cols={"20"}
                            rows={"8"}
                            value={content} onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                    <div className='mt-5'>
                        <button className='btn more' disabled={loading} onClick={handleContactform}>
                            {
                                loading ? HomePage.loading[lang] : HomePage.sendMessage[lang]
                            }
                        </button>
                    </div>
                    <div className="text-center">
                        <FaFacebookSquare size={"35px"} className='mx-2' />
                        <FaXTwitter size={"35px"} className='mx-2' />
                        <MdEmail size={"35px"} className='mx-2' />

                        <p style={{ color: "#828282", marginTop: "10px" }}>El-Fath El-Araby 2024</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
